// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery'
import "bootstrap";
import "../src/application";
import 'slick-carousel'
import 'slick-carousel/slick/slick.css';
import '@fortawesome/fontawesome-free/css/all.css'

global.$ = jQuery;

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import "./autoselectPrefecture"
import "./common"
import "./fav"
import "./searchArea"
import "./top"
// import "./linkGoogle"
// import "./editAvailableCalendar"
// import "./availableTime"
// import "./itemDetail"
// import "./publisherTop"
// import "./reserve"
// import "./confirmItem"
// import "./createItem"
