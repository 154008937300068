$(function () {
  // 市区町村入力
  // 都道府県
  $prefSelect = $('select[name="prefecture"]')
  $citySelect = $('select[name="city"]')

  const prefLoadStart = () => {
    $prefSelect.attr('disabled', 'disabled').find('option:eq(0)').html('読み込み中')
  }
  const prefLoadEnd = () => {
    $prefSelect.attr('disabled', false).find('option:eq(0)').html('都道府県を選択')
  }
  const cityLoadStart = () => {
    $citySelect.attr('disabled', 'disabled').find('option:eq(0)').html('読み込み中')
  }
  const cityLoadEnd = () => {
    $citySelect.attr('disabled', false).find('option:eq(0)').html('市区町村を選択')
  }

  // 初期表示
  prefLoadStart()
  $.getJSON('/api/internal/v1/prefectures').done(function (json) {
    const prefs = json.result;
    const selectedKenId = $('input[name="selected_ken_id"]')
    prefs.forEach(function(pref) {
      const $option = $("<option>").val(pref.ken_id).html(pref.ken_name)
      if (selectedKenId.length) {
        $option.attr('selected', selectedKenId.val() == pref.ken_id)
      }
      $prefSelect.append($option)
    });
    if (selectedKenId.length && $('input[name="selected_city_id"]').length === 0) {
      cityLoadStart()
      getCities(selectedKenId.val())
    }
    prefLoadEnd()
  })

  $citySelect.attr('disabled', 'disabled')
  // 市区町村
  $('select[name="prefecture"]').on('change', function () {
    $citySelect
        .empty()
        .append('<option value="">市区町村を選択</option>');
    cityLoadStart()

    const kenId = $(this).val();
    if (kenId === '') {
      return
    }

    getCities(kenId)
  })

  // 画面描画時に既に市区町村がセットされている場合の市区町村取得
  if ($('input[name="selected_city_id"]').length && $('input[name="selected_city_id"]').val() !== 0) {
    const selectedKenId = $('input[name="selected_ken_id"]').val()
    const selectedCityId = $('input[name="selected_city_id"]').val()
    getCities(selectedKenId, selectedCityId)
  }

  function getCities(kenId, cityId = null) {
    $.getJSON('/api/internal/v1/cities/' + kenId).done(function (json) {
      const cities = json.result;
      cities.forEach(function (city) {
        const $option = $("<option>").val(city.city_id).html(city.city_name);
        if (cityId != null) {
          $option.attr('selected', cityId == city.city_id)
        }
        $citySelect.append($option)
      })
      cityLoadEnd()
    })
  }
})
