import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

let inputDate = ''

document.addEventListener('DOMContentLoaded', function () {
  const calendarEls = document.querySelectorAll('.js-searchAreaCalendar')
  calendarEls.forEach(function (el) {
    const calendar = new Calendar(el, {
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
      initialView: 'dayGridMonth',
      validRange: function(currentDate){
        const startDate = new Date(currentDate.valueOf());
        const endDate = new Date(currentDate.valueOf());

        startDate.setDate(startDate.getDate() + 3);
        endDate.setMonth(endDate.getMonth() + 6);

        return {start: startDate, end: endDate};
      },
      locale: 'ja',
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next',
      },
      height: 'auto',
      dayCellContent: function (e) {
        e.dayNumberText = e.dayNumberText.replace('日', '')
      },
      dateClick: function (date) {
        inputDate = date.dateStr
        $('.fc-daygrid-day').removeClass('isSelected')
        $(`.fc-daygrid-day[data-date="${inputDate}"]`).addClass('isSelected')
        $('.js-calendarAreaSelectedDate').text(inputDate)

        setInputDate(el)
      },
    })
    calendar.render()
  })
})

const setInputDate = () => {
  if (!inputDate) {
    return
  }
  // 入力欄にセット
  $('.js-searchDateValue').val(inputDate);
  $('input[name="search_date"]').val(inputDate)
  setDisplayDate(inputDate)
}
const clearInputDate = () => {
  $('.js-searchDateValue').val('');
  $('input[name="search_date"]').val('')
  setDisplayDate('')
}

const setDisplayDate = function (inputDate) {
  if (inputDate !== '') {
    $('.js-searchDate').removeClass('hasNotDateValue')
    $('.js-searchDate').html(`<span>${inputDate}</span>`)
  } else {
    $('.js-searchDate').addClass('hasNotDateValue')
    $('.js-searchDate').empty()
  }
};

$(function () {

  // 入力欄フォーカスでチップを表示
  $('.js-searchDate').on('click', function () {
    $('.js-calendarArea').addClass('isVisible')
  })

  $('.resetBtn').on('click', function () {
    clearInputDate()
    return false
  })

  setDisplayDate($('input[name="search_date"]').val())

  // 場所指定
  // 入力欄フォーカスでチップを表示
  $('.js-searchPlace').on('click', function () {
    $('.js-placeArea').addClass('isVisible')
  })

  if ($('.js-searchPlace').is('clicked')) {
    closeTipArea('js-placeArea')
  }

  // 入力後チップを消す
  $('.js-searchPlace').on('change', function () {
    $('.js-placeArea').removeClass('isVisible')
  })

  // 適用ボタン押す
  $('.js-acceptPlace').on('click', function (e) {
    e.preventDefault()
    $('.js-placeArea').removeClass('isVisible')

    const pref = $(this).closest('form').find('select[name="prefecture"]')
    const city = $(this).closest('form').find('select[name="city"]')
    let place =
      (pref.val() !== '' ? pref.find('option:selected').text() : '') +
      (city.val() !== '' ? ' ' + city.find('option:selected').text() : '')

    // 入力欄にセット
    $('.js-searchPlaceValue').val(place)
    setDisplayPlace(place);
    $('input[name="ken_id"]').val(pref.val());
    $('input[name="city_id"]').val(city.val());
  })

  const setDisplayPlace = function (place) {
    if (place !== '') {
      $('.js-searchPlace').removeClass('hasNotPlaceValue')
      $('.js-searchPlace').html(`<span>${place}</span>`)
    } else {
      $('.js-searchPlace').addClass('hasNotPlaceValue')
      $('.js-searchPlace').empty()
    }
  };

  // 場所の初期値
  setDisplayPlace($('input[name="search_place"]').val());

  // チップ以外クリックでチップを閉じる
  const tipAreas = [
    ['.js-calendarArea', '.js-searchDate'],
    ['.js-placeArea', '.js-searchPlace'],
  ]
  tipAreas.forEach((area) => {
    $(document).on('click', function (e) {
      if (
        !$(e.target).closest(area[0]).length &&
        !$(e.target).closest(area[1]).length
      )
        $(area[0]).removeClass('isVisible')
    })
  })

  // チップ消すボタン
  $('.js-searchTipClose').on('click', function (e) {
    e.preventDefault()
    $(this).closest('.js-searchTipArea').removeClass('isVisible')
  })
})
