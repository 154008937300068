import { Modal } from "bootstrap";

$(function() {
  const searchPath = location.pathname === "/" ? "/" : "/spaces";

  const doSubmit = function() {
    const conditions = [];
    [
      "search_date",
      "start_time",
      "end_time",
      "ken_id",
      "city_id",
      "user_count",
      "category",
      "order",
    ].forEach((key) => {
      const paramValue = $("input[name='initial_param_" + key + "']").val();
      if (paramValue) {
        conditions.push(key + "=" + paramValue);
      }
    });
    location.href = searchPath + "?" + conditions.join("&");
  };

  $(".js-showOrder, .js-showSpacetype").on("change blur", function() {
    const category = $(".js-showSpacetype").val();
    $("input[name='initial_param_category']").val(category);
    const order = $(".js-showOrder").val();
    $("input[name='initial_param_order']").val(order);
    doSubmit();
  });

  // 初期表示
  $(".js-showSpacetypeText").text(
    $(".js-showSpacetype option:selected").text(),
  );
  $(".js-showOrderText").text($(".js-showOrder option:selected").text());

  // 未成年 法定代理人の同意モーダル表示が必要であれば、モーダルを表示する
  if (gon.open_underage_modal) {
    const modal = new Modal(document.getElementById("underageModal"), {
      backdrop: true,
    });
    modal.show();
  }
});
