$(function () {
  const toggleFavorite = (spaceId, favoriteCount, isAdd) => {
      const $button = $(`.js-itemFav[data-space-id=${spaceId}]`)
      $button.find('.js-favCount').text(favoriteCount)
      $button.next('.js-favCount').text(favoriteCount)
      const text = $button.find('.tip')
      if (isAdd) {
          $button.addClass('isFav')
          text.text('お気に入りから削除')
      } else{
          $button.removeClass('isFav')
          text.text('お気に入りに追加')
      }
  }

    // 初期化時お気に入りの場合isFavクラスを追加
  $('.js-itemFav').each(function () {

    $(this).on('click', function (e) {
      e.preventDefault();
      const $button = $(this);
      if ($button.hasClass("sending")) {
        return;
      }
      const ajaxParam = {
          url: "/api/internal/v1/space_favorites",
          data: {space_id: $button.data("space-id")},
      };
      $button.addClass("sending");
      // isFavクラスの有無でお気に入りAPIを投げる
      if ($button.hasClass("isFav")) {
        $.ajax(Object.assign({type: "DELETE"}, ajaxParam ))
            .done(function (data) {
                toggleFavorite($button.data('space-id'), data.result.favorite_count, false)
            })
            .fail(function (jqXHR, status, e) {
                console.error(status, e);
            })
            .always(function () {
                $button.removeClass("sending");
            });
      } else {
          $.ajax(Object.assign({type: "POST"}, ajaxParam ))
              .done(function (data) {
                  toggleFavorite($button.data('space-id'), data.result.favorite_count, true)
              })
              .fail(function (jqXHR, status, e) {
                  console.error(status, e);
              })
              .always(function () {
                  $button.removeClass("sending");
              });
      }
      return false
    })
  })

  // SPでお気に入りメッセージ切り替え
  $('.js-toastTrigger').on('click', function (e) {
    e.preventDefault()
    const toastName = $(this).attr('data-toast-name')
    const toast = $(`.js-toast[data-toast-name="${toastName}"]`)
    $(this).hasClass('isFav')
      ? toast.text('お気に入りを解除しました。')
      : toast.text('お気に入りを追加しました。')
  })
})
