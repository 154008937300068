$(function () {
  // トグルメニュー
  $(".js-toggleBtn").on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass("isShown");
    $(this).next(".js-toggleContent").toggleClass("isShown");
    const toggleName = $(this).attr("name");
    const self = $(this);

    // お知らせ開いた＆未読がある時のみ既読にする
    if (
      toggleName == "toggleNotice" &&
      $('[name="toggleNotice"] .badge').length
    ) {
      // すぐ消すとなぜかメニューが閉じてしまうのでsetTimeoutで消す
      setTimeout(() => {
        $('[name="toggleNotice"] .badge').remove();
      }, 100);
      $.ajax({
        url: "/api/internal/v1/notification/all_read",
        type: "PUT",
      });
    }

    // 外側をクリックすると閉じる
    $(document).on("click", function (e) {
      if (
        !$(e.target).closest(`.js-toggleContent[name="${toggleName}"]`)
          .length &&
        !$(e.target).closest(`.js-toggleBtn[name="${toggleName}"]`).length
      ) {
        $(`.js-toggleContent[name="${toggleName}"]`).removeClass("isShown");
        self.removeClass("isShown");
      }
    });
  });

  // トグル閉じるボタン
  $(".js-toggleClose").on("click", function (e) {
    e.preventDefault();
    $(".js-toggleBtn,.js-toggleContent").removeClass("isShown");
  });

  // トップアンカー
  $('a[href^="#"]').click(function () {
    const speed = 500;
    const href = $(this).attr("href");
    const target = $(href == "#" || href == "" ? "html" : href);
    const position = target.offset().top - 100;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  // SPモーダル
  $(".js-spmenuBtn").on("click", function (e) {
    e.preventDefault();
    const name = $(this).attr("name");
    $(`.js-spmenu[name="${name}"]`).fadeIn(100);
    // お知らせ開いた＆未読がある時のみ既読にする
    if (name === "spNotice" && $('[name="spNotice"] .badge').length) {
      $('[name="spNotice"] .badge').remove();
      $.ajax({
        url: "/api/internal/v1/notification/all_read",
        type: "PUT",
      });
    }
  });

  $(".js-spmenuClose").on("click", function (e) {
    e.preventDefault();
    $(this).parents(".js-spmenu").fadeOut(100);
  });

  // トーストメッセージの表示
  let toastTimer;
  $(".js-toastTrigger").on("click", function (e) {
    e.preventDefault();

    const toastName = $(this).attr("data-toast-name");
    const toast = $(`.js-toast[data-toast-name="${toastName}"]`);
    const displayTime = toast.data("display-time");

    clearTimeout(toastTimer);
    toast.fadeIn(100);
    if (displayTime) {
      toastTimer = setTimeout(function () {
        toast.fadeOut(100);
      }, displayTime);
    }
  });

  // フッターまでスクロールしたらフロートをフッターの上に来るようにして、アンカーとその他ボタンもあげる
  $(window).on("scroll", function () {
    const [docHeight, windowHeight, windowScroll, footerHeight] = [
      $(document).innerHeight(),
      window.innerHeight,
      $("html").scrollTop() || $("body").scrollTop(),
      60,
    ];

    const pageBottom = docHeight - windowHeight;
    if (pageBottom - windowScroll <= footerHeight) {
      $(".js-topAnchor.hasFloat,.js-floatNav,.js-spmenuBtn").addClass(
        "isPageBottom",
      );
    } else {
      $(".js-topAnchor.hasFloat,.js-floatNav,.js-spmenuBtn").removeClass(
        "isPageBottom",
      );
    }
  });

  const openSMSModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    $(".js-snsModal").fadeIn(100);
    $("body").css("overflow-y", "hidden");
  };

  if (gon.open_sms_modal) {
    openSMSModal();
  }

  //SNS認証の案内モーダル
  $(".js-snsModalTrigger").on("click", openSMSModal);

  $(".js-snsModalClose,.js-snsModal").on("click", function (e) {
    e.preventDefault();
    $(".js-snsModal").fadeOut(100);
    $("body").css("overflow-y", "auto");
  });

  $(".js-snsModalClickArea").on("click", function (e) {
    e.stopPropagation();
  });

  // --------------------
  // 未成年同意モーダル
  // --------------------
  // 画面読み込み時
  if ($("#underage_agree").prop("checked")) {
    $("#underage_agree").closest(".agreecheck_area").addClass("is_checked");
    $(".js_btn_submit").prop("disabled", false);
  } else {
    $("#underage_agree").closest(".agreecheck_area").removeClass("is_checked");
    $(".js_btn_submit").prop("disabled", true);
  }
  // チェックボックスを押した時
  $("#underage_agree").on("click", function () {
    var $agreecheck_area = $(this).closest(".agreecheck_area");
    if ($(this).prop("checked")) {
      $agreecheck_area.addClass("is_checked");
      $(".js_btn_submit").prop("disabled", false);
    } else {
      $agreecheck_area.removeClass("is_checked");
      $(".js_btn_submit").prop("disabled", true);
    }
  });
  // ラベルを押した時
  $(".agreecheck_area label").on("click", function () {
    var $agreecheck_area = $(this).closest(".agreecheck_area");
    var $checkbox = $agreecheck_area.find("input[type=checkbox]");
    if ($checkbox.prop("checked")) {
      $agreecheck_area.removeClass("is_checked");
      $(".js_btn_submit").prop("disabled", false);
    } else {
      $agreecheck_area.addClass("is_checked");
      $(".js_btn_submit").prop("disabled", true);
    }
  });

  // SPで下部にメニューバーがあるページの上に戻るボタンデザイン調整
  if (location.pathname !== "/" && $(".js-floatNav").length) {
    $("footer.l-footer").addClass("hasFloat");
    $("footer > a").addClass("hasFloat");
    $(".l-spSearchBtn").addClass("hasFloat");
  }

  //「いいね！を送る」モーダル
  $(".js-reviewModalTrigger").on("click", function (e) {
    e.preventDefault();

    // form の action を動的に変更
    const data = $(this).data();
    $("#reviewModalForm").attr("action", data.action);

    // タイトル変更
    $("#reviewModalTitle").html(
      `${data.userName}さんとの<span class="bold">${data.spaceName}</span>のやりとりは完了しました！`,
    );

    // 初期化
    $("#reviewModalComment").val("");
    $(".js-reviewModal").removeClass("is_confirm");

    $(".js-reviewModal").fadeIn(100);
    $("body").css("overflow-y", "hidden");
  });

  $(".js-reviewModalClose,.js-reviewModal").on("click", function (e) {
    e.preventDefault();

    $(".js-reviewModal").fadeOut(100);
    $("body").css("overflow-y", "auto");
  });

  $(".js-reviewModalClickArea").on("click", function (e) {
    e.stopPropagation();
  });

  //「いいね！を送る」モーダルの確認ボタン
  $(".js-register").on("click", function () {
    $(".js-reviewModal").addClass("is_confirm");

    // 確認画面のコメントに値をセット
    const comment = $("#reviewModalComment").val();
    $("#reviewModalInputBlock").text(comment);

    // コメントが未入力か判定（改行、スペースのみは、未入力とする）
    if (!comment.match(/\S/g)) {
      $("#reviewModalInputBlock").hide();
      $("#reviewModalNoComment").show();

      // コメントを空文字にする
      $("#reviewModalComment").val("");
    } else {
      $("#reviewModalInputBlock").show();
      $("#reviewModalNoComment").hide();
    }
  });

  $(".js-returnBtn").on("click", function () {
    $(".js-reviewModal").removeClass("is_confirm");
  });
});

//submitボタンをおしたらローディングアイコンを表示
$(function () {
  // ブラウザバック対策で毎回display none
  $(".js-sending").css("display", "none");
  $(".js-formLoading").submit(function () {
    $(this).find(".js-submitBtn").prop("disabled", true);
    $(".js-sending").css("display", "block");
  });
});
